import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import StorybookEmbed from "../../../components/StorybookEmbed.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentDropdown.figmaUrl.website} codeUrl={checklists.componentDropdown.codeUrl.website} checklists={checklists.componentDropdown.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Dropdowns are used to display a list of user-selectable actions or options`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/dropdown/android-compose-dropdown.gif",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The following is code to implement Dropdown component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`data class DropdownItem(
    override val id: Int,
    override val label: String,
    override val iconLeft: ImageVector? = null,
    override val iconRight: ImageVector? = null,
    override val divider: Boolean = false,
    override val enabled: Boolean = true
) : ILgnDropdownMenuItem

val data = listOf(
    DropdownItem(
        id = 1,
        label = "New File",
        iconLeft = Icons.Default.Add
    ),
    DropdownItem(
        id = 1,
        label = "View Detail",
        iconLeft = Icons.Default.Face
    )
)

var expanded by remember { mutableStateOf(false) }

LgnDropdownMenu(
    data = data,
    expanded = expanded,
    onDismissRequest = { expanded = false },
    onClickItem = {
        expanded = false
    }
) {
    LgnButton(onClick = { expanded = !expanded })
}

`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add List Data Dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`expanded`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Whether The Dropdown is Expand or Not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismissRequest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Action When The Dropdown is Dismiss`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`offset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DpOffset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Size Offset of Dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`scrollstate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ScrollState`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Scroll State of Dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`properties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PopupProperties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Pop Up Properties of Dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onClickItem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Action When The Item of Dropdown is Clicked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Button For Open and Close Dropdown`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      